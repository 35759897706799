declare const window: any;

import { isOnServer } from "@tm/ui-widgets";
import { ANDROID, IOS, getMobileOperatingSystem } from "./common";

const USER_LOCATION_APP_STORAGE_KEY = "location";

/**
 * Function to get version of the app
 * @returns {String} AppVersion
 */

const getAppVersion = () => {
  if (isOnServer()) {
    return null;
  }
  const mobileOS = getMobileOperatingSystem();
  let version = null;
  try {
    if (mobileOS.toLowerCase() === ANDROID) {
      //@ts-ignore
      version = window?.app_bridge?.getAppVersion?.();
      let mmtAndroidAppVersion: any = {};
      if (version) {
        try {
          mmtAndroidAppVersion = JSON.parse(decodeURIComponent(version));
          return mmtAndroidAppVersion?.app_version_name;
        } catch (e) {
          console.error(
            `Error parsing app version. Got version: ${version}`,
            e
          );
          return null;
        }
      }
    } else if (mobileOS.toLowerCase() === IOS) {
      //@ts-ignore
      version = window?.app_bridge?.getAppVersion();
      return version;
    }
  } catch (e) {
    console.error("error while fetching app version: " + e);
    return null;
  }
};

const setUserLocationInAppStorage = (data: any) => {
  if (window?.app_bridge?.setInSessionStorage) {
    window.app_bridge.setInSessionStorage(
      USER_LOCATION_APP_STORAGE_KEY,
      JSON.stringify(data)
    );
  } else if (window?.webkit?.messageHandlers?.setUserSessionStorage) {
    window.webkit.messageHandlers.setUserSessionStorage.postMessage({
      key: USER_LOCATION_APP_STORAGE_KEY,
      value: JSON.stringify(data),
    });
  }
};

export const BridgeMethods = {
  getAppVersion: getAppVersion,
  setUserLocationInAppStorage,
};
