import {
  APP_SOCIAL_URL,
  COOKIES,
  DEVICE_OS,
  getUserIdentifier,
  GET_SLT_TOKEN_URL,
  GI_SUB_DOMAIN,
  HOST,
  MakeMyTrip,
  MMT_LOGIN_URL,
  MMT_SUB_DOMAIN,
  SOURCE,
  TM_AUTH_EXPIRY_DAYS,
  USER_SERVICE_TM_AUTH,
  USER_SERVICE_TOKEN,
  getUserIdentifierForSocialLogin,
  MMT_FOREX_URL,
  GoIbibo,
  MMT_DESKTOP_DIV,
  loginHandlerGIMweb,
  loginHandlerGIDWeb,
  IOS,
  RedirectURL,
  ANDROID,
  LOGIN_TYPE,
  ACCOUNT_TYPE,
} from "../constants";
import {
  isPartnerHost,
  getCookieExpiryTime,
  getUserDeviceOS,
  persistSpecificParamInCookie,
  getHostName,
  isMobile,
  getPartner,
  getMmtLoginCustomEvent,
  isApp,
  removeTmMmmtAuthCookie,
  getEmailLoginErrorCustomEvent,
  fetchutmParamsFromCookie,
  getTmAuthDefaultObj,
  TmAuthDataType,
  getLoginErrorCustomEvent,
  getAppUpdateCustomEvent,
  getAppSocialLoginQuery,
  deleteSpecificCookie,
  isAndroidAuthBridgePresent,
  getAccountType,
  getMmtAgentLoginCustomEvent,
} from "../common";
import { getCookie } from "../common/cookieUtils";
//@ts-ignore
import MMT_UI from "MMT-UI/visitorDvid/visitorDvid.js";
import { fetchApi } from "../common/fetchApi";
import { getSLToken, logoutUser, saveTMToken } from "../methods/bridgeMethods";
import {
  encodeJSON,
  getTmAuthFromIosBridge,
  getTmAuthJsonForIos,
} from "../methods";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";

const { getVisitorId } = MMT_UI;

const validateMmtAuth = async (isOnlyValidate = false, redirectUrl = "") => {
  if (isApp() && !isAuthBridgesPresent()) {
    //handle update app popup
    document.dispatchEvent(
      getAppUpdateCustomEvent({
        showModal: true,
      })
    );
    return false;
  }

  if (isApp() && getUserDeviceOS() === IOS) {
    removeTmMmmtAuthCookie(false);
    await getTmAuthFromIosBridge(MakeMyTrip);
  }
  const tm_auth = getCookie(COOKIES.TM_AUTH_KEY, true);
  const mmt_auth = getCookie(COOKIES.MMT_AUTH_KEY, true);
  const tm_mmt_auth = getCookie(COOKIES.TM_MMT_AUTH_KEY, true);

  if (!mmt_auth) {
    if (isApp() && tm_auth) {
      return true;
    }
    if (isOnlyValidate) {
      return false;
    }
    return partnerLogin(redirectUrl);
  }

  if (tm_auth) {
    //user is loggedin
    if (!tm_mmt_auth) {
      if (isOnlyValidate) {
        return false;
      }
      return;
    }
    const split_tm_mmt_auth = tm_mmt_auth.split("___");
    if (mmt_auth !== split_tm_mmt_auth[1] || tm_auth !== split_tm_mmt_auth[0]) {
      if (isOnlyValidate) {
        return false;
      }
      return generateTmAuthUsingPartnerAuth();
    }
    if (isOnlyValidate) {
      return true;
    }
  } else {
    if (isOnlyValidate) {
      return false;
    }
    return generateTmAuthUsingPartnerAuth();
  }
};

const validateGiAuth = async (isOnlyValidate = false, redirectUrl = "") => {
  if (isApp() && !isAuthBridgesPresent()) {
    //handle update app
    document.dispatchEvent(
      getAppUpdateCustomEvent({
        showModal: true,
      })
    );
    return false;
  }
  if (isApp() && getUserDeviceOS() === IOS) {
    removeTmMmmtAuthCookie(false);
    await getTmAuthFromIosBridge(GoIbibo);
  }
  const tm_auth = getCookie(COOKIES.TM_AUTH_KEY, true);
  const gi_auth = getCookie(COOKIES.GI_AUTH_KEY, true);
  const tm_gi_auth = getCookie(COOKIES.TM_GI_AUTH_KEY, true);

  if (!gi_auth) {
    if (isApp() && tm_auth) {
      return true;
    }
    if (isOnlyValidate) {
      return false;
    }
    return partnerLogin(redirectUrl);
  }
  if (tm_auth) {
    //user is loggedin
    if (!tm_gi_auth) {
      if (isOnlyValidate) {
        return false;
      }
      return;
    }
    const split_tm_mmt_auth = tm_gi_auth.split("___");
    if (gi_auth !== split_tm_mmt_auth[1] || tm_auth !== split_tm_mmt_auth[0]) {
      if (isOnlyValidate) {
        return false;
      }
      return generateTmAuthUsingPartnerAuth();
    }
    if (isOnlyValidate) {
      return true;
    }
  } else {
    if (isOnlyValidate) {
      return false;
    }
    return generateTmAuthUsingPartnerAuth();
  }
};

const validateForexAgentAuth = async (isOnlyValidate = false) => {
  const agent_auth = getCookie(COOKIES.FOREX_AGENT_AUTH_KEY, true);
  if (!agent_auth) {
    if (isOnlyValidate) {
      return false;
    }
    return partnerLogin();
  }
  if (isOnlyValidate) {
    return true;
  }
};

const partnerLogin = (redirectUrl = "") => {
  removeTmMmmtAuthCookie();
  if (isApp()) {
    //app bridge handling
    appLogin(redirectUrl);
  } else {
    if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
      mmtLoginRedirection();
    } else if (isPartnerHost(SOURCE.GOIBIBO)) {
      //handling for Goibibo
      goibiboLoginRedirection();
    }
  }
};

export const setUtmParamStr = () => {
  const utmParams = fetchutmParamsFromCookie();
  const utm_source = utmParams?.utm_source || "no_source";
  const utm_medium = utmParams?.utm_medium || "";
  const utm_content = utmParams?.utm_content || "";
  const utm_campaign = utmParams?.utm_campaign || "";
  const utmStr = `${utm_source ? `utm_source=${utm_source}` : ""}${
    utm_medium ? `&utm_medium=${utm_medium}` : ""
  }${utm_content ? `&utm_content=${utm_content}` : ""}${
    utm_campaign ? `&utm_campaign=${utm_campaign}` : ""
  }`;
  return utmStr;
};
// const loginHandlerGIDWeb = (encodedString: string, redirectUrl: string) => {
//     const url = `https://${process.env['NODE_ENV'] === 'production' ? "goibibo.com" : "newprodpp.goibibo.com"}/login/vendor/?lob=tripmoney&customData=${encodedString}&next=${redirectUrl}`
//     window.location.href = url;
// }
// const loginHandlerGIMweb = (encodedString: string, redirectUrl: string) => {
//     const url = `https://${process.env['NODE_ENV'] === 'production' ? "goibibo.com" : "newprodpp.goibibo.com"}/login/?lob=tripmoney&customData=${encodedString}&next=${redirectUrl}`
//     window.location.href = url;
// }

const mmtLoginRedirection = () => {
  const utmStr = setUtmParamStr() || "";
  if (getHostName() !== HOST.MAKEMYTRIP && isMobile()) {
    const redirectUrl =
      '"' +
      window.location.href +
      `${
        utmStr
          ? `${window.location.href.includes("?") ? "&" : "?"}${utmStr}`
          : ""
      }` +
      '"';
    persistSpecificParamInCookie(
      COOKIES.MMT_LOGIN_REDIRECT_KEY,
      getHostName() === HOST.MAKEMYTRIP ? MMT_FOREX_URL : redirectUrl,
      {
        path: "/",
        domain: MMT_SUB_DOMAIN,
      }
    );
  }
  if (isMobile()) {
    window.location.href = MMT_LOGIN_URL;
  } else {
    document.dispatchEvent(
      getMmtLoginCustomEvent({
        activeModal: "LOGIN",
      })
    );
    // window.location.href = MMT_FOREX_URL;
  }
};

const goibiboLoginRedirection = () => {
  const device = getUserDeviceOS();
  const defaultCustomData = {
    userName: "",
    isSocial: false,
    isOnlyPhoneMode: true,
    isTokenEnable: true,
    prefillMobileNumber: "",
    isMobileNumberDisabled: false,
    enableTruecaller: false,
    skipMobileScreen: true,
    partner: "GI",
    userUrl: "",
  };

  const customDatajson = JSON.stringify(defaultCustomData);

  const encodedString = btoa(customDatajson);

  const utmStr = setUtmParamStr() || "";

  const redirectUrl =
    window.location.href +
    `${
      utmStr ? `${window.location.href.includes("?") ? "&" : "?"}${utmStr}` : ""
    }`;

  if (device === DEVICE_OS.ANDROID || device === DEVICE_OS.IOS) {
    loginHandlerGIMweb(encodedString, redirectUrl);
  } else {
    loginHandlerGIDWeb(encodedString, redirectUrl);
  }
};

const generateTmAuthUsingPartnerAuth = async () => {
  const tmAuthResultObj: TmAuthDataType = getTmAuthDefaultObj();
  const sltTokenData = isApp()
    ? await getShortLiveTokenForApp()
    : await getShortLiveToken();
  const accountType = await getAccountType();
  if (sltTokenData?.success) {
    tmAuthResultObj.loginToken.success = true;
    const reqBody = {
      accType: accountType,
      authToken: sltTokenData?.data?.encryptedToken,
      query: getAppSocialLoginQuery,
    };
    const tmAuthResponse = await getAuth(reqBody);
    if (tmAuthResponse?.success) {
      tmAuthResultObj.appSocialToken.success = true;
      const tmAuth = tmAuthResponse?.data?.profileLoginResponse?.[0]?.authToken;
      const mmtAuth = getCookie(COOKIES.MMT_AUTH_KEY, true);
      const giAuth = getCookie(COOKIES.GI_AUTH_KEY, true);
      const loginInfoList =
        tmAuthResponse?.data?.profileLoginResponse?.[0]?.userDetails
          ?.extendedUser?.loginInfoList || [];
      const isMobileRegistered = loginInfoList.find(
        (e: any) => e?.loginType === LOGIN_TYPE.MOBILE
      );
      const corporateData = JSON.parse(
        tmAuthResponse?.data?.profileLoginResponse?.[0]?.userDetails
          ?.extendedUser?.corporateData || "{}"
      );
      const phoneNumberVerified = corporateData?.employee?.phoneNumberVerified;
      if (!isMobileRegistered && !phoneNumberVerified) {
        console.log("Error: Email login not supported");
        tmAuthResultObj.appSocialToken.error = {
          code: "4401",
          description: "Email login not supported",
        };
        tmAuthResultObj.showEmailPopup = true;
        document.dispatchEvent(
          getEmailLoginErrorCustomEvent({
            showModal: true,
          })
        );

        return tmAuthResultObj;
      }
      if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
        persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, tmAuth, {
          path: "/",
          domain: MMT_SUB_DOMAIN,
          expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
        });
        //Need to keep mapping of mmt_auth and tm_auth to  validate  partner and tm_auth mismatch
        persistSpecificParamInCookie(
          COOKIES.TM_MMT_AUTH_KEY,
          `${tmAuth}___${mmtAuth}`,
          {
            path: "/",
            domain: MMT_SUB_DOMAIN,
            expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
          }
        );

        if (getUserDeviceOS() === IOS) {
          saveTMToken(encodeJSON(getTmAuthJsonForIos(mmtAuth, tmAuth)));
        }
      } else if (isPartnerHost(SOURCE.GOIBIBO)) {
        //handling for Goibibo
        persistSpecificParamInCookie(COOKIES.TM_AUTH_KEY, tmAuth, {
          path: "/",
          domain: GI_SUB_DOMAIN,
          expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
        });
        persistSpecificParamInCookie(
          COOKIES.TM_GI_AUTH_KEY,
          `${tmAuth}___${giAuth}`,
          {
            path: "/",
            domain: GI_SUB_DOMAIN,
            expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
          }
        );
        if (getUserDeviceOS() === IOS) {
          saveTMToken(encodeJSON(getTmAuthJsonForIos(giAuth, tmAuth)));
        }
      }
    } else if (tmAuthResponse?.code === 4401) {
      console.log("Error: Email login not supported");
      tmAuthResultObj.appSocialToken.error = {
        code: "4401",
        description: "Email login not supported",
      };
      tmAuthResultObj.showEmailPopup = true;
      document.dispatchEvent(
        getEmailLoginErrorCustomEvent({
          showModal: true,
        })
      );
      // return {
      //     showEmailPopup: true
      // }
    } else {
      tmAuthResultObj.appSocialToken.error = {
        code: tmAuthResponse?.code || "UNKNOWN",
        description: tmAuthResponse?.message || "Error in fetching tmAuth",
      };
      console.log("Error: In fetching tmAuth");

      //show login fail popup
      document.dispatchEvent(
        getLoginErrorCustomEvent({
          showModal: true,
        })
      );
    }
  } else {
    tmAuthResultObj.loginToken.error = {
      code: sltTokenData?.code || "UNKNOWN",
      description:
        sltTokenData?.message || "Error in fetching short live token",
    };
    console.log("Error: in fetching short live token");
    //show login fail popup

    if (isApp()) return;
    document.dispatchEvent(
      getLoginErrorCustomEvent({
        showModal: true,
      })
    );
  }
  return tmAuthResultObj;
};

const getShortLiveTokenForApp = async () => {
  const promise = new Promise((resolve, reject) => {
    window.shortLivToken = (token?: string) => {
      resolve({
        success: token ? true : false,
        data: {
          encryptedToken: token,
        },
      });
    };

    try {
      if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
        getSLToken(MakeMyTrip);
      } else if (isPartnerHost(SOURCE.GOIBIBO)) {
        getSLToken(GoIbibo);
      }
    } catch (err) {
      console.log("Error", err);
      resolve(null);
    }
  });
  return promise;
};

const getShortLiveToken = async () => {
  const mmt_auth = getCookie(COOKIES.MMT_AUTH_KEY, true);
  const gi_auth = getCookie(COOKIES.GI_AUTH_KEY, true);
  let auth: any = "";
  const deviceId = getVisitorId();
  if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
    auth = mmt_auth;
  } else if (isPartnerHost(SOURCE.GOIBIBO)) {
    auth = gi_auth;
  }
  const data: any = {
    client: "TM",
  };
  try {
    const response = await fetchApi(GET_SLT_TOKEN_URL(getPartner()), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: USER_SERVICE_TOKEN,
        "User-Identifier": getUserIdentifier(auth, deviceId),
        deviceid: deviceId,
        "x-request-tracker": uuidv4(),
      },
      data,
    });
    return response;
  } catch (err) {
    console.log("Error", err);
    return null;
  }
};

export async function getAuth(data: any) {
  const mmt_auth = getCookie(COOKIES.MMT_AUTH_KEY, true);
  const gi_auth = getCookie(COOKIES.GI_AUTH_KEY, true);
  let auth: any = "";
  const accountType = await getAccountType();
  if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
    auth = mmt_auth;
  } else if (isPartnerHost(SOURCE.GOIBIBO)) {
    auth = gi_auth;
  }
  try {
    const deviceId = getVisitorId();
    const headers: any = {
      "Content-Type": "application/json",
      Authorization: USER_SERVICE_TM_AUTH,
      "User-Identifier": getUserIdentifierForSocialLogin(auth, deviceId),
      "x-request-tracker": uuidv4(),
    };
    if (accountType === ACCOUNT_TYPE.MYBIZ) {
      headers["x-gommt-client-id"] = ACCOUNT_TYPE.MYBIZ;
    }
    const response = await fetchApi(APP_SOCIAL_URL(getPartner()), {
      method: "POST",
      headers: headers,
      data: data,
    });
    return response;
  } catch (err) {
    console.error("Error", err);
    return null;
  }
}

const appLogin = (redirectUrl = "") => {
  //@ts-ignore
  window.onLoginSuccess = (auth = "") => {
    console.log("Login successful");
    if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
      persistSpecificParamInCookie(COOKIES.MMT_AUTH_KEY, auth, {
        path: "/",
        domain: MMT_SUB_DOMAIN,
        expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
      });
    } else if (isPartnerHost(SOURCE.GOIBIBO)) {
      persistSpecificParamInCookie(COOKIES.GI_AUTH_KEY, auth, {
        path: "/",
        domain: GI_SUB_DOMAIN,
        expires: getCookieExpiryTime(TM_AUTH_EXPIRY_DAYS),
      });
    }
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      window.location.href = `${window.location.href}`;
    }
  };

  //@ts-ignore
  window.onLoginCancelled = () => {
    console.log("Login cancelled");
    window.location.reload();
  };

  loginUser();
};

interface LoginOptionType {
  loginId?: string | null;
  countryCode?: string | null;
  allowedType?: string | null;
  editable?: boolean | null;
  showReferral?: boolean | null;
  socialActionEnabled?: boolean | null;
  bannerText?: string | null;
  bannerIcon?: string | null;
  icon?: string | null;
  header?: string | null;
  description?: string | null;
  bottomSheet?: boolean | null;
}

const loginUser = (loginJson?: LoginOptionType) => {
  const defaultLoginJson = {
    // loginId: null,
    countryCode: "91",
    allowedType: "mobile",
    type: "mobile",
    editable: true,
    showReferral: false,
    socialActionEnabled: false,
    // bannerText: null,
    // bannerIcon: null,
    // icon: null,
    header: "Login to continue",
    // description: null,
    bottomSheet: true,
  };
  loginJson = { ...defaultLoginJson, ...loginJson };
  if (getUserDeviceOS() === DEVICE_OS.ANDROID) {
    //@ts-ignore
    window?.app_bridge?.login?.(JSON.stringify(loginJson));
  } else {
    //@ts-ignore
    window?.webkit.messageHandlers?.login?.postMessage(loginJson);
  }
};

export const loginToPartner = async (redirectUrl = "") => {
  if (isApp()) {
    return partnerLogin(redirectUrl);
  }
  if (isPartnerHost(SOURCE.FOREX_EXPERT)) {
    validateForexAgentAuth();
  } else if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
    return validateMmtAuth();
  } else if (isPartnerHost(SOURCE.GOIBIBO)) {
    return validateGiAuth();
  }
};

export const isUserLoggedin = async () => {
  if (isPartnerHost(SOURCE.FOREX_EXPERT)) {
    return validateForexAgentAuth(true);
  } else if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
    return validateMmtAuth(true);
  } else if (isPartnerHost(SOURCE.GOIBIBO)) {
    return validateGiAuth(true);
  }
  return false;
};

export const logout = async () => {
  if (isApp()) {
    removeTmMmmtAuthCookie();
    if (!isAuthBridgesPresent()) {
      return;
    }
    return logoutUser();
  }
  return removeTmMmmtAuthCookie();
};

export const isPartnerLoggedIn = async () => {
  if (isPartnerHost(SOURCE.FOREX_EXPERT)) {
    return isUserLoggedin();
  }
  const mmt_auth = getCookie(COOKIES.MMT_AUTH_KEY, true);
  const gi_auth = getCookie(COOKIES.GI_AUTH_KEY, true);
  if (!(mmt_auth || gi_auth) && isApp()) {
    if (!isAuthBridgesPresent()) {
      //show popup
      document.dispatchEvent(
        getAppUpdateCustomEvent({
          showModal: true,
        })
      );
      return false;
    }
    if (getUserDeviceOS() === IOS) {
      removeTmMmmtAuthCookie();
      if (isPartnerHost(SOURCE.MAKEMYTRIP)) {
        await getTmAuthFromIosBridge(MakeMyTrip);
      } else if (isPartnerHost(SOURCE.GOIBIBO)) {
        await getTmAuthFromIosBridge(GoIbibo);
      }
    }
    const tm_auth = getCookie(COOKIES.TM_AUTH_KEY, true);
    if (tm_auth) {
      return true;
    }
    const tmAuthRes: any = await generateTmAuthUsingPartnerAuth();
    if (tmAuthRes?.appSocialToken?.success) {
      return true;
    }
    return false;
  }
  return mmt_auth || gi_auth ? true : false;
};

export const loginIfPartnerLoggedin = async () => {
  if (isPartnerHost(SOURCE.FOREX_EXPERT)) {
    return null;
  }
  removeTmMmmtAuthCookie(false);
  const isPartnerLoggedin = await isPartnerLoggedIn();
  if (isPartnerLoggedin) {
    return generateTmAuthUsingPartnerAuth();
  }
  return null;
};

export const isAuthBridgesPresent = () => {
  if (getUserDeviceOS() === ANDROID) {
    if (isAndroidAuthBridgePresent()) {
      return true;
    }
  } else {
    if (
      window?.webkit?.messageHandlers?.logoutUser &&
      window?.webkit?.messageHandlers?.login &&
      window?.webkit?.messageHandlers?.getShortLivToken
    ) {
      return true;
    }
  }
  return false;
};

export function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const getMyAccountType = getAccountType;
