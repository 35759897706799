import {
  useEffect,
  useState,
  lazy,
  Suspense,
  MouseEventHandler,
  MouseEvent,
  useRef,
} from "react";

import TopShowcase from "../../static/components/ListingPageComponents/TopShowcase";
import TopShowcaseV3 from "../../static/components/ListingPageComponents/TopShowcaseV3";

import { landingCardShowcaseDataProps } from "../../static/mockData";

import useForexWidgetProps from "../../hooks/useForexWidgetProps";
import { getLocationFetchingScreenLabel } from "../../utils/componentPropsMapper";
import { GlobalStyles } from "../../global-style/index.style";
import useRedirectionUrls from "../../hooks/useRedirectionUrls";

import { useDevice } from "../../hooks/useDevice";
import PageLoader from "../../static/components/PageLoader";
import { FullPageWrapper } from "../../static/components/ErrorScreen/ErrorScreen.style";
import useRedirectForexProductLinksV2 from "../../hooks/useRedirectForexProductsLinksV2";
import useFetchCardCtaSignalState from "../../hooks/useFetchCardCtaSignalState";
import {
  closeWebview,
  isMobile,
  getDeviceType,
  OFFER_TYPES,
  getEventDetails,
  getEventTrackingContext,
  IEventTrackingContext,
  dispatchEmailLoginCustomEvent,
  getHostName,
  generateRequestId,
  isPartnerHost,
  getExperimentDetails,
  getXpressRtApiUrl,
  getPageName,
  isAgentFlow,
  getComponentsPdt,
  getDeliveryDetails,
} from "../../utils";
import useFetchListingPostLocationChange from "../../hooks/useFetchListingPostLocationChange";
import {
  PDT_EVENTS,
  MakeMyTrip,
  Goibibo,
  PROFILE_URL,
  DEVICE_MODEL_TYPE,
  CARD_TO_ACTION_MAPPING,
  CARD_TO_ACTION_RESUME_MAPPING,
  CARD_TO_ACTION_QUICKLOAD_MAPPING,
  VERSIONS,
  HOST,
  SESSION_STORAGE_KEYS,
  getSessionPiiDataOpts,
  MYRA_PAGE_NAME,
  MYRA_LOB_CATEGORY,
  getPartner,
} from "../../constants";
import { useGlobalSelector } from "../../hooks/useGlobalSelector";
import { ListingReducerType } from "../../store/forexListingReducer";
import { useListingPdtLogger } from "../../hooks/usePdtLogger";

import EmailLoginModal from "../EmailLoginModal";
import DowntimeModal from "../../static/components/DowntimeModal";
import useScrolledMwebHeaderContent from "../../hooks/useScrolledMwebHeaderContent";
import useOpenLoginForm from "../../hooks/useOpenLoginForm";
import { redirectInterceptHref } from "../../static/utils";
import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import useRefreshCurrencyRates from "../../hooks/useRefreshCurrencyRates";
import useListingPdtLoggerV2 from "../../hooks/useListingPdtLoggerV2";
import useCoachmarks from "../../hooks/useCoachmarks";
import { useGlobalDispatch } from "../../hooks/useGlobalDispatch";
import { updateForceLoginEmailModal } from "../../store/action";
import {
  getCouponDetailProps,
  getDeliveryPersuasion,
  getDiscountBannerProps,
  getLandingTitle,
  getLocationFetchingDataProps,
  getOtherProductsText,
  getOtherShowcaseCardsData,
  getSectionBgImgURI,
  getViewOrderLabel,
  getForexRateTickerProps,
  getEffectiveRateBannerLabel,
  getPropsForTickerModal,
} from "../../utils/topShowCasePropsMapper";

import CouponDetailsModal from "../../static/components/CouponDetailsModal/CouponDetailsModal";
import { Coupon } from "../../types";
import { theme } from "../../static/css/Global";
import { useFreezeBodyScroll } from "../../hooks/useFreezeBodyScroll";
import ForexRatesModal from "../../static/components/ForexRatesModal/ForexRatesModal";
import useTrackListingClickEvent from "../../hooks/useTrackListingClickEvent/useTrackListingClickEvent";
import { postXpressRtData } from "../../api/fetchApi";
import { XpressRtDataTypes } from "../../utils/xpressRtUtils";
import { getSessionStorageItem } from "../../utils/clientStorageUtils";
import { setSessionStorageItem } from "../../utils/clientStorageUtils";
import { getExperimentDetailsForPdt } from "../../utils/pdtutils/listingPdtUtils";

const AssitanceBot = lazy(
  () => import(/* webpackChunkName: 'AssistanceBot' */ "../AssistanceBot")
);

const EditLocationWrapper = lazy(
  () =>
    import(
      /* webpackChunkName: 'EditLocationWrapper' */ "../EditLocationWrapper"
    )
);

const ErrorScreen = lazy(
  () =>
    import(
      /* webpackChunkName: 'ErrorScreen' */ "../../static/components/ErrorScreen/ErrorScreen"
    )
);

export const ASSISTANCE_FLOW_TYPE = {
  MYRA: "MYRA",
  IVR: "IVR",
};

interface IForexCardsProps {
  getonLoginCallback?: (fn: () => void) => void;
}

const ForexCards = ({ getonLoginCallback }: IForexCardsProps) => {
  const listingDetailsReqIdRef = useRef<string | null>(null);
  const device = useDevice();
  const { partner, isLoggedInUser, mobileNumberRequiredError } =
    useGlobalConfig();
  const {
    isErrorDeliveryDetails,
    downtime,
    persona,
    isSuccessListingDetailsOnLocChange,
    forex_rates,
    tm_user_id,
    products,
    puid,
    offer_persuasions,
    recommended_currencies_ticker,
    experiments,
    best_rate_hyperlink = "",
    assistance_flow,
    pricing_nudge,
    delivery_nudge,
  } = useGlobalSelector((state: ListingReducerType) => state);

  const { upcoming_trip: upcomingTrip } = persona || {};
  const {
    isLocationFetching,
    isPageLoading,
    locFetchingMode,
    showEmailLoginPopup,
    setShowEmailLoginPopup,
  } = useFetchListingPostLocationChange(
    isLoggedInUser as boolean | null,
    listingDetailsReqIdRef
  );

  const [isEditLocModalOpen, setEditModalLocOpen] = useState(false);

  const [showCouponsModal, setShowCouponsModal] = useState(false);
  const [showPriceRatesModal, setShowPriceRatesModal] = useState(false);

  const {
    sendListingPdtEventOnLocationBarClick,
    sendPdtProductTilesCtaClickEvent,
    sendPdtPersuasionLinkCtaClickEvent,
    sendPdtFDOCtaClickEvent,
    sendPdtUnavailableLocationCtaClickEvent,
    sendPdtLocationBottomSheetViewEvent,
    sendPdtViewDashboardCtaClickEvent,
    sendPdtViewOrdersCtaClickEvent,
    sendPdtCouponShown,
    sendPdtViewCoupons,
    sendPdtListingPageViewEvent,
    sendCtaEvent,
    myraIconShownPdtLoadEvent,
  } = useListingPdtLogger();

  const { sendCTAData, sendPdtLocationBottomSheetViewEventV2, sendPdtDataV2 } =
    useListingPdtLoggerV2();

  const {
    mobileTitle,
    showTickerAtBottomInMobile,
    landingPinnedCardShowcaseData,
    landingCardShowcaseData,
    deliveryDetailsContent,
    // version,
    deliveryDetailsContentV3,
  } = useForexWidgetProps();

  const { trackListingClickEvents } = useTrackListingClickEvent();

  const version = VERSIONS.V3;

  const TopShowCaseComp = version === VERSIONS.V3 ? TopShowcaseV3 : TopShowcase;

  const { isLoadingCardCtaSignalState, isErrorCardCtaSignalState } =
    useFetchCardCtaSignalState();
  const [showCardCtaErrorScreen, setShowCardCtaErrorScreen] = useState(
    isErrorCardCtaSignalState
  );

  const { showCurrencyOrderCoachmark, closeCurrencyOrderCoachmark } =
    useCoachmarks();

  const {
    landingPageRedirect,
    quickLoadMoneyRedirections,
    redirectUploadDocs,
    trackCardRedirections,
    viewDashboardRedirections,
    viewOrdersRedirections,
    startOrderRedirections,
    reloadTripRedirection,
  } = useRedirectionUrls();

  const { redirectForexProductLinksV2 } = useRedirectForexProductLinksV2();

  const locationFetchingScreenLabel = getLocationFetchingScreenLabel(
    locFetchingMode,
    deliveryDetailsContent,
    persona,
    version,
    isErrorDeliveryDetails
  );

  const locationFetchingScreenLabelV3 = getLocationFetchingScreenLabel(
    locFetchingMode,
    deliveryDetailsContentV3,
    persona,
    version,
    isErrorDeliveryDetails
  );

  const pdtExperimentDetailsObj = getExperimentDetails(
    getExperimentDetailsForPdt(experiments)
  );

  const { scrolledMWebHeaderContent } = useScrolledMwebHeaderContent();

  const { openLoginFormForNonLoggedInUser } = useOpenLoginForm();

  const dispatch = useGlobalDispatch();

  useRefreshCurrencyRates();

  // Pass callback to consumer app, it will be called once the user login on partner platform successfully.
  useEffect(() => {
    getonLoginCallback?.(refreshPage);
  }, []);

  useFreezeBodyScroll(showCouponsModal);

  const showAssistanceBotMyra =
    assistance_flow?.type === ASSISTANCE_FLOW_TYPE.MYRA;

  useEffect(() => {
    if (showAssistanceBotMyra) {
      myraIconShownPdtLoadEvent();
      sendPdtDataV2({
        event_detail: getEventDetails({
          event_name: PDT_EVENTS.LISTING_MYRA_BOT_ICON_SHOWN,
        }),
      });
    }
  }, [showAssistanceBotMyra]);

  useEffect(() => {
    setShowCardCtaErrorScreen(isErrorCardCtaSignalState);
  }, [isErrorCardCtaSignalState]);

  useEffect(() => {
    setSessionStorageItem(SESSION_STORAGE_KEYS.FOREX_EXPERIMENTS, experiments);
  }, [experiments, device]);

  useEffect(() => {
    if (
      [OFFER_TYPES.CROSS_SELL_HOTEL, OFFER_TYPES.CROSS_SELL_FLIGHT].includes(
        offer_persuasions?.[0]?.type || ""
      ) &&
      offer_persuasions?.[0]?.value?.text
    ) {
      const details = offer_persuasions?.[0]?.value?.details as Coupon[];
      const couponShown = `${offer_persuasions[0]?.type}-${details?.[0]?.booking_id}-${details?.[0]?.coupon_cashback}`;
      const totalCoupons = details?.length || 0;
      sendPdtCouponShown(
        PDT_EVENTS.LISTING_COUPON_SHOWN,
        couponShown,
        totalCoupons
      );
      sendCTAData({
        eventDetail: getEventDetails({
          event_name: PDT_EVENTS.LISTING_COUPON_SHOWN,
          event_value: `${couponShown}--Total Coupons-${totalCoupons}`,
        }),
      });
    } else if (offer_persuasions?.[0]?.type === OFFER_TYPES.SALE_COUPONS) {
      sendPdtCouponShown(
        PDT_EVENTS.LISTING_COUPON_SHOWN,
        OFFER_TYPES.SALE_COUPONS,
        1
      );
      sendCTAData({
        eventDetail: getEventDetails({
          event_name: PDT_EVENTS.LISTING_COUPON_SHOWN,
          event_value: `${OFFER_TYPES.SALE_COUPONS}--Total Coupons-1`,
        }),
      });
    }
  }, [offer_persuasions]);
  useEffect(() => {
    if (
      isSuccessListingDetailsOnLocChange &&
      getHostName() !== HOST.MAKEMYTRIP
    ) {
      const deliveryDetails =
        getSessionStorageItem(
          SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
          true,
          getSessionPiiDataOpts
        ) || null;
      sendPdtListingPageViewEvent({
        persona,
        forex_rates,
        tm_user_id,
        products,
        puid,
      });
      const { intl_travel, country_code, start_date, end_date } =
        upcomingTrip ?? {};
      sendPdtDataV2({
        event_detail: getEventDetails({
          event_name: PDT_EVENTS.PAGE_LOAD_EVENT,
          upcoming_trip: {
            intl_travel,
            trip_details: [
              {
                country_code,
                start_date,
                end_date,
              },
            ],
          },
          delivery_details: getDeliveryDetails({
            city: deliveryDetails?.city,
            pincode: deliveryDetails?.pincode,
            deliverable: deliveryDetails?.deliverable,
            eta: deliveryDetails?.eta_in_days,
            state: deliveryDetails?.state,
            state_code: deliveryDetails?.state_code,
          }),
          components: getComponentsPdt({
            content_details: [
              {
                description:
                  Object.keys(pricing_nudge || {}).length > 0
                    ? "RATE_INCREASE_NUDGE"
                    : "NA",
              },
              {
                description:
                  Object.keys(delivery_nudge || {}).length > 0
                    ? "ORDER_TIMER"
                    : "NA",
              },
            ],
          }),
        }),
        event_tracking_context: getEventTrackingContext({
          request_id: listingDetailsReqIdRef.current,
        } as IEventTrackingContext),
        experiment_details: pdtExperimentDetailsObj,
      });
    }
  }, [isSuccessListingDetailsOnLocChange]);

  // FOR LANDING FEATURES CARD
  const loadCurrenyBtnHandler = async (item: landingCardShowcaseDataProps) => {
    if (!isLoggedInUser) {
      await openLoginFormForNonLoggedInUser();
      return;
    }

    await sendPdtPersuasionLinkCtaClickEvent(
      PDT_EVENTS.QUICK_LOAD_CTA,
      item.label
    );

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.QUICK_LOAD_CTA,
      event_value: item.label,
    });

    sendPdtDataV2({
      event_detail: eventDetail,
      experiment_details: pdtExperimentDetailsObj,
    });

    trackListingClickEvents({
      actionType: CARD_TO_ACTION_QUICKLOAD_MAPPING[item.label],
    });
    quickLoadMoneyRedirections(item);
  };

  const recommendedBtnHandler = async (item: landingCardShowcaseDataProps) => {
    if (!isLoggedInUser) {
      await openLoginFormForNonLoggedInUser();
      return;
    }
    await sendPdtPersuasionLinkCtaClickEvent(
      PDT_EVENTS.TRACK_CARD_CTA,
      item.label
    );

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.TRACK_CARD_CTA,
      event_value: item.label,
    });
    sendCTAData({ eventDetail });
    trackListingClickEvents({
      actionType: "track_button_clicked",
    });
    trackCardRedirections(item);
  };

  const startFromWhereLeftHandler = async (
    item: landingCardShowcaseDataProps
  ) => {
    sendPdtFDOCtaClickEvent(item.label);

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.COMPLETE_ORDER_CTA,
      event_value: item.label,
    });
    const reqId = generateRequestId();
    sendCTAData({
      eventDetail,
      event_tracking_context: getEventTrackingContext({
        request_id: reqId,
      } as IEventTrackingContext),
    });

    trackListingClickEvents({
      actionType: CARD_TO_ACTION_RESUME_MAPPING[item.label],
    });
    await sendXpressRtEvent(item);
    await redirectForexProductLinksV2(item, reqId);
  };

  const pinnedShowcaseCardHandler = async (
    item: landingCardShowcaseDataProps
  ) => {
    await sendPdtProductTilesCtaClickEvent(item.label);

    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.PRODUCT_TILE_CTA,
      event_value: item.label,
    });

    const reqId = generateRequestId();
    sendCTAData({
      eventDetail,
      event_tracking_context: getEventTrackingContext({
        request_id: reqId,
      } as IEventTrackingContext),
    });
    trackListingClickEvents({
      actionType: CARD_TO_ACTION_MAPPING[item.label],
    });
    await sendXpressRtEvent(item);
    await redirectForexProductLinksV2(item, reqId);
  };

  const otherShowcaseCardsHandler = async (
    item: landingCardShowcaseDataProps
  ) => {
    await sendPdtProductTilesCtaClickEvent(item.label);
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.PRODUCT_TILE_CTA,
      event_value: item.label,
    });

    const reqId = generateRequestId();
    sendCTAData({
      eventDetail,
      event_tracking_context: getEventTrackingContext({
        request_id: reqId,
      } as IEventTrackingContext),
    });
    trackListingClickEvents({
      actionType: CARD_TO_ACTION_MAPPING[item.label],
    });
    await sendXpressRtEvent(item);
    await redirectForexProductLinksV2(item, reqId);
  };

  const docsPendingHandler = async (item: landingCardShowcaseDataProps) => {
    if (!isLoggedInUser) {
      await openLoginFormForNonLoggedInUser();
      return;
    }

    await sendPdtPersuasionLinkCtaClickEvent(
      PDT_EVENTS.UPLOAD_DOCS_CTA,
      item.label
    );
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.UPLOAD_DOCS_CTA,
      event_value: item.label,
    });
    sendCTAData({ eventDetail });

    redirectUploadDocs(item);
  };

  const editLocationHandler = () => {
    setEditModalLocOpen(true);
    sendListingPdtEventOnLocationBarClick();
    sendCTAData({
      eventDetail: getEventDetails({
        event_name: PDT_EVENTS.LOCATION_BAR_CTA,
      }),
    });
    sendPdtLocationBottomSheetViewEvent();
    sendPdtLocationBottomSheetViewEventV2();
    trackListingClickEvents({
      actionType: "location_dropdown_clicked",
    });
  };

  const deliveryUnavailbleClickHandler: MouseEventHandler<HTMLSpanElement> = (
    e: MouseEvent
  ) => {
    e.stopPropagation();
    setEditModalLocOpen(true);
    sendListingPdtEventOnLocationBarClick();
    sendPdtLocationBottomSheetViewEvent();
    trackListingClickEvents({
      actionType: "location_dropdown_clicked",
    });
  };

  const onEditLocModalClose = () => {
    setEditModalLocOpen(false);
  };

  const onClickAnotherLocation = (item: landingCardShowcaseDataProps) => {
    sendPdtUnavailableLocationCtaClickEvent(item.label);
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.UNAVAILABLE_LOC,
      event_value: item.label,
    });
    sendCTAData({ eventDetail });
    setEditModalLocOpen(true);
  };

  const checkPincodeHandler = () => {
    setEditModalLocOpen(true);
    trackListingClickEvents({
      actionType: "location_dropdown_clicked",
    });
  };

  function refreshPage() {
    window.location.reload();
  }

  const onClickBackFromErrorScreen = () => {
    setShowCardCtaErrorScreen(false);
  };

  const viewDashBoardClickHandler = async (
    data: landingCardShowcaseDataProps
  ) => {
    if (!isLoggedInUser) {
      await openLoginFormForNonLoggedInUser();
      return;
    }

    await sendPdtViewDashboardCtaClickEvent(data?.label);
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.VIEW_DASHBOARD,
      event_value: data?.label,
    });
    sendPdtDataV2({
      event_detail: eventDetail,
      experiment_details: pdtExperimentDetailsObj,
    });

    viewDashboardRedirections(data);
  };
  const viewOrdersClickHandler = async (
    data: landingCardShowcaseDataProps | { label: string; version: number }
  ) => {
    if (!isLoggedInUser) {
      await openLoginFormForNonLoggedInUser();
      return;
    }

    await sendPdtViewOrdersCtaClickEvent(data?.label); // Need to be changed
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.VIEW_ORDERS,
      event_value: data?.label,
    });
    sendCTAData({ eventDetail });
    viewOrdersRedirections(data);
  };
  const onCloseEmailLogin = () => {
    setShowEmailLoginPopup(false);
    if (getDeviceType() === DEVICE_MODEL_TYPE.APP) {
      closeWebview();
      return;
    }
    if (getPartner() === MakeMyTrip) {
      if (isMobile()) {
        redirectInterceptHref(PROFILE_URL.MMT_PWA, true);
      } else {
        redirectInterceptHref(PROFILE_URL.MMT_DT, true);
      }
      return;
    } else if (getPartner() === Goibibo) {
      redirectInterceptHref(PROFILE_URL.GI, true);
      return;
    }
  };

  const handleLoginClick = async () => {
    dispatch(updateForceLoginEmailModal(false));

    if (mobileNumberRequiredError) {
      dispatchEmailLoginCustomEvent(true);
      return;
    }

    if (!isLoggedInUser) {
      await openLoginFormForNonLoggedInUser();
      return;
    }
  };

  const startOrderClicked = async (data: landingCardShowcaseDataProps) => {
    sendPdtPersuasionLinkCtaClickEvent(
      PDT_EVENTS.START_ORDER_CTA,
      data.startOrder?.linkBtnInMobile.label
    );
    await sendXpressRtEvent(data);
    startOrderRedirections(data);
  };

  const sendXpressRtEvent = async (data: any) => {
    if (!isLoggedInUser) {
      return;
    }
    if (isAgentFlow()) {
      return;
    }
    const apiUrl = getXpressRtApiUrl(partner);
    const xpressRtData: Partial<XpressRtDataTypes> = {};
    const city_code =
      getSessionStorageItem(
        SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
        true,
        getSessionPiiDataOpts
      )?.city_code || "";
    xpressRtData.source = getPageName();
    xpressRtData.current_city = city_code;
    xpressRtData.product = data?.id || "";
    const { country_code, start_date, end_date } = upcomingTrip ?? {};
    xpressRtData.trip_details = {
      country: country_code || "",
      start_timestamp: start_date,
      end_timestamp: end_date,
      currency: "",
      amount: null,
    };
    await postXpressRtData(apiUrl, xpressRtData);
  };

  function handleAvatarClick() {
    if (version === VERSIONS.V3) {
      viewOrdersClickHandler({ label: "avatar_click", version: VERSIONS.V3 });
    }
  }

  const locationFetchingDataProps = getLocationFetchingDataProps(
    locFetchingMode,
    isLocationFetching,
    locationFetchingScreenLabel
  );

  const locationFetchingDataPropsV3 = getLocationFetchingDataProps(
    locFetchingMode,
    isLocationFetching,
    locationFetchingScreenLabelV3
  );
  const sectionBgImgURI = getSectionBgImgURI(version, isLocationFetching);
  const landingTitle = getLandingTitle(mobileTitle);
  const otherProductsText = getOtherProductsText(
    landingCardShowcaseData,
    version
  );
  const deliveryPersuasion = getDeliveryPersuasion(
    deliveryDetailsContent,
    checkPincodeHandler,
    editLocationHandler
  );
  const otherShowcaseCardsData = getOtherShowcaseCardsData(
    landingCardShowcaseData,
    otherShowcaseCardsHandler
  );
  const viewOrderLabel = getViewOrderLabel(isLoggedInUser);

  const sendCrossSellPdtEvent = ({
    eventName,
    eventValue,
  }: {
    eventName: string;
    eventValue: string;
  }) => {
    sendCtaEvent({
      eventName: eventName,
      primaryValue: eventValue,
    });
    sendPdtDataV2({
      event_detail: getEventDetails({
        event_name: eventName,
        event_value: eventValue,
      }),
    });
  };

  const reloadTripHandler = (data: landingCardShowcaseDataProps) => {
    const eventDetail = getEventDetails({
      event_name: PDT_EVENTS.RELOAD_CURRENT_TRIP,
      event_value: data?.label,
    });
    sendCTAData({ eventDetail });
    reloadTripRedirection(data);
  };

  const effectiveRateBanner = getEffectiveRateBannerLabel({
    offerPersuasions: offer_persuasions,
    device,
  });

  const tickerModalProps = getPropsForTickerModal({
    forexRateTicker: recommended_currencies_ticker,
    offerPersuasions: offer_persuasions,
  });

  return (
    <>
      {downtime === true ? <DowntimeModal /> : null}
      <GlobalStyles />
      <TopShowCaseComp
        pricingNudge={pricing_nudge}
        deliveryNudge={delivery_nudge}
        isShowDiscountBanner={false}
        discountBannerBgColor={
          device !== "lg" &&
          offer_persuasions?.[0]?.type === OFFER_TYPES.CROSS_SELL_HOTEL &&
          offer_persuasions?.[0]?.value?.text
            ? theme.greenBg
            : ""
        }
        reloadTripHandler={reloadTripHandler}
        isPageLoading={isPageLoading}
        isPageLoadingGif="https://tripmoneycmsimgak.mmtcdn.com/img/infinity_Loading_min_f0a0b9bcba.gif"
        isPageLoadingText="Loading Forex Offerings..."
        locationFetchingData={locationFetchingDataProps}
        version={version}
        isMarqueeOnBottom={showTickerAtBottomInMobile}
        otherProductsText={otherProductsText}
        deliveryPersuasion={deliveryPersuasion}
        landingTitle={landingTitle}
        mobileTitle={mobileTitle}
        sectionBgImgURI={sectionBgImgURI}
        // marqueeData={marqueeData}
        pinnedShowcaseCardData={{
          data: landingPinnedCardShowcaseData,
          onClick: pinnedShowcaseCardHandler,
        }}
        loadCurrenyBtnHandler={loadCurrenyBtnHandler}
        recommendedBtnHandler={recommendedBtnHandler}
        docsPendingHandler={docsPendingHandler}
        startFromWhereLeftHandler={startFromWhereLeftHandler}
        onClickAnotherLocation={onClickAnotherLocation}
        viewDashBoardClickHandler={viewDashBoardClickHandler}
        viewOrdersClickHandler={viewOrdersClickHandler}
        startOrderClicked={startOrderClicked}
        deliveryUnavailbleClickHandler={deliveryUnavailbleClickHandler}
        otherShowcaseCardsData={otherShowcaseCardsData}
        sendCrossSellPdtEvent={sendCrossSellPdtEvent}
        viewOrderLabel={viewOrderLabel}
        locationFetchingDataV3={locationFetchingDataPropsV3}
        mobileStickyHeadingData={{
          userAvatarImgURI:
            "https://tripmoneycmsimgak.mmtcdn.com/img/listing_avatar_7f9e7d2ae5.png",
          onClickBack: landingPageRedirect,
          isScrolled: scrolledMWebHeaderContent,
          rightCtaTxt: "LOG IN",
          handleLoginClick,
          isLoggedIn: isLoggedInUser as boolean, //isUserLoggedin() as boolean
          handleAvatarClick: handleAvatarClick,
          orderCount: persona?.currency_active_orders,
          showCoachmark: showCurrencyOrderCoachmark,
          coachmarkClickHandler: closeCurrencyOrderCoachmark,
          version: version,
        }}
        // Removed this banner as discount period is finished. Can be utilised for future banners
        //update as required
        discountBanner={getDiscountBannerProps(offer_persuasions, device)}
        // showInfoIcon={
        //     !!(
        //         offer_persuasions?.[0]?.type ===
        //             OFFER_TYPES.CROSS_SELL_HOTEL &&
        //         offer_persuasions?.[0]?.value?.details?.[0]
        //             ?.coupon_cashback
        //     )
        // }
        // discountInfoIconHandler={() => {
        //     if (
        //         offer_persuasions?.[0]?.type ===
        //         OFFER_TYPES.CROSS_SELL_HOTEL
        //     ) {
        //         sendPdtViewCoupons(
        //             PDT_EVENTS.LISTING_VIEW_COUPONS_CLICK
        //         );
        //         sendCTAData({
        //             eventDetail: getEventDetails({
        //                 event_name:
        //                     PDT_EVENTS.LISTING_VIEW_COUPONS_CLICK,
        //             }),
        //         });
        //     }
        //     setShowCouponsModal(true);
        // }}
        discountOfferHandler={() => setShowCouponsModal(true)}
        showEffectiveRateBanner={effectiveRateBanner ? true : false}
        effectiveRateBannerLabel={effectiveRateBanner}
        effectiveRateIconHandler={() => setShowCouponsModal(true)}
        priceTickerData={getForexRateTickerProps({
          forexRateTicker: recommended_currencies_ticker,
          offerPersuasions: offer_persuasions,
        })}
        showPriceTicker
        viewAllPriceHandler={() => {
          setShowPriceRatesModal(true);
        }}
        desktopLandingTitle={`<span class="greenText boldText font16">Door-step Delivery</span> | Across 185+ Indian cities`}
      />
      {isEditLocModalOpen && (
        <Suspense fallback={<PageLoader />}>
          <EditLocationWrapper
            isModalOpen={isEditLocModalOpen}
            onModalClose={onEditLocModalClose}
            listingDetailsReqIdRef={listingDetailsReqIdRef}
          />
        </Suspense>
      )}
      {isLoadingCardCtaSignalState && <PageLoader />}
      {showCardCtaErrorScreen && (
        <FullPageWrapper>
          <Suspense fallback={<PageLoader />}>
            <ErrorScreen
              errorType="PAGE"
              retryHandler={refreshPage}
              onClickBack={onClickBackFromErrorScreen}
            />
          </Suspense>
        </FullPageWrapper>
      )}
      {showEmailLoginPopup && <EmailLoginModal onClose={onCloseEmailLogin} />}
      {showCouponsModal && (
        <CouponDetailsModal
          onModalClose={() => {
            setShowCouponsModal(false);
          }}
          {...getCouponDetailProps(offer_persuasions)}
        />
      )}
      {showPriceRatesModal && (
        <ForexRatesModal
          modalHeading="Forex Rates"
          onModalClose={() => setShowPriceRatesModal(false)}
          title={tickerModalProps.title}
          forexRatesData={tickerModalProps.forexRatesData}
          footerText={tickerModalProps.footerText}
        />
      )}

      {showAssistanceBotMyra && (
        <Suspense fallback={<></>}>
          <AssitanceBot
            page={MYRA_PAGE_NAME.LISTING}
            lobCategory={MYRA_LOB_CATEGORY.LISTING}
          />
        </Suspense>
      )}
    </>
  );
};

export default ForexCards;
